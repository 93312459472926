import axios, { AxiosRequestConfig, AxiosResponse } from "axios"
import { windowLock, windowRelease } from "../utils/loader"
import { NotificationService } from "../features/NotificationService/NotificationService"

export class SysApi {
  // protected static baseUrl = "http://localhost:3000/"
  protected static baseUrl = "https://zubov-api.marketplacer.store/"

  protected static async post<T = any, R = AxiosResponse<T>, D = any>(
    url: string,
    data?: D,
    config?: AxiosRequestConfig<D>,
    withLockWindow = true,
  ): Promise<R> {
    const token = localStorage.getItem("token")
    if (!token) {
      location.replace("login")
      throw new Error("Пользователь не авторизирован")
    }
    if (withLockWindow) {
      windowLock()
    }
    try {
      const res = await axios.post(url, data, {
        ...config,
        headers: {
          ...config?.headers,
          Authorization: `Bearer ${token}`,
        },
      })

      if (withLockWindow) {
        windowRelease()
      }
      // @ts-expect-error
      return res
    } catch (e: any) {
      if (withLockWindow) {
        windowRelease()
      }
      NotificationService.showNotification({
        level: "error",
        message:
          e?.response?.data?.message ||
          "Произошла ошибка при обращении к серверу, обратитесь к администратору",
        errorBody: e?.response?.data?.e || JSON.stringify(e),
      })
      throw e
    }
  }
  protected static async get<T = any, R = AxiosResponse<T>, D = any>(
    url: string,
    config?: AxiosRequestConfig<D>,
    withLockWindow = true,
  ): Promise<R> {
    const token = localStorage.getItem("token")
    if (!token) {
      location.replace("login")
      throw new Error("Пользователь не авторизирован")
    }
    if (withLockWindow) {
      windowLock()
    }
    try {
      const res = await axios.get(url, {
        ...config,
        headers: {
          ...config?.headers,
          Authorization: `Bearer ${token}`,
        },
      })
      if (withLockWindow) {
        windowRelease()
      }

      // @ts-expect-error
      return res
    } catch (e: any) {
      if (withLockWindow) {
        windowRelease()
      }
      NotificationService.showNotification({
        level: "error",
        message:
          e?.message ||
          "Произошла ошибка при обращении к серверу, обратитесь к администратору",
        errorBody: JSON.stringify(e),
      })
      throw e
    }
  }
  protected static async delete<T = any, R = AxiosResponse<T>, D = any>(
    url: string,
    config?: AxiosRequestConfig<D>,
    withLockWindow = true,
  ): Promise<R> {
    const token = localStorage.getItem("token")
    if (!token) {
      location.replace("login")
      throw new Error("Пользователь не авторизирован")
    }
    if (withLockWindow) {
      windowLock()
    }
    try {
      const res = await axios.delete(url, {
        ...config,
        headers: {
          ...config?.headers,
          Authorization: `Bearer ${token}`,
        },
      })
      if (withLockWindow) {
        windowRelease()
      }
      // @ts-expect-error
      return res
    } catch (e: any) {
      if (withLockWindow) {
        windowRelease()
      }
      NotificationService.showNotification({
        level: "error",
        message:
          e?.message ||
          "Произошла ошибка при обращении к серверу, обратитесь к администратору",
        errorBody: JSON.stringify(e),
      })
      throw e
    }
  }

  static auth(data: { username: string; password: string }) {
    return axios.post(this.baseUrl + "login", data).then(({ data }) => {
      const token = data.token
      if (data.success) {
        localStorage.setItem("token", data.token)
        location.replace("/")
      }
      return (token && "ok") || data
    })
  }
}
