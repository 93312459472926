import React, { useEffect, useState } from "react"
import { Calculator } from "./Calculator"
import s from "./Settings.module.css"

type IPriceSettingProps = {
  onSave: (args: {
    margin: number
    profit: number
    logistic: number
    ff: number
    coef: number
    tax: number
    sale: number
    priemka?: number
    ekv?: number
    mila?: number
  }) => void
  margin?: number
  profit?: number
  logistic?: number
  ff?: number
  coef?: number
  tax?: number
  sale?: number
  priemka?: number
  ekv?: number
  mila?: number
  shop: "ICON" | "KATREN" | "KATREN_OZON" | "PODRUZHKA"
  onChange: (args: {
    margin?: number
    profit?: number
    logistic?: number
    ff?: number
    coef?: number
    tax?: number
    sale?: number
    priemka?: number
    ekv?: number
    mila?: number
  }) => void
}

export const PriceSetting = (props: IPriceSettingProps) => {
  const {
    margin,
    profit,
    logistic,
    ff,
    coef,
    tax,
    sale,
    onSave,
    onChange,
    shop,
    priemka,
    ekv,
    mila,
  } = props
  return (
    <div>
      <div className={s.priceSetting}>
        <div>
          <h5>Изменение параметров цены</h5>
          <div>
            <div className={s.inputWrap}>
              <label htmlFor="margin">Маржинальность</label>
              <input
                value={margin}
                onChange={(e) =>
                  onChange({
                    profit,
                    logistic,
                    ff,
                    coef,
                    tax,
                    margin: Number(e.target.value),
                    sale,
                    priemka,
                    ekv,
                    mila,
                  })
                }
                name="margin"
                type="number"
                min="0"
              />
            </div>
            <div className={s.inputWrap}>
              <label htmlFor="profit">Минимальная прибль</label>
              <input
                value={profit}
                onChange={(e) =>
                  onChange({
                    margin,
                    logistic,
                    ff,
                    coef,
                    tax,
                    profit: Number(e.target.value),
                    sale,
                    priemka,
                    ekv,
                    mila,
                  })
                }
                name="profit"
                type="number"
                min="0"
              />
            </div>
            <div className={s.inputWrap}>
              <label htmlFor="logistic">Логистика</label>
              <input
                value={logistic}
                onChange={(e) =>
                  onChange({
                    margin,
                    profit,
                    ff,
                    coef,
                    tax,
                    logistic: Number(e.target.value),
                    sale,
                    priemka,
                    ekv,
                    mila,
                  })
                }
                name="logistic"
                type="number"
                min="0"
              />
            </div>
            <div className={s.inputWrap}>
              <label htmlFor="ff">ФФ</label>
              <input
                value={ff}
                onChange={(e) =>
                  onChange({
                    margin,
                    profit,
                    logistic,
                    coef,
                    tax,
                    ff: Number(e.target.value),
                    sale,
                    priemka,
                    ekv,
                    mila,
                  })
                }
                name="ff"
                type="number"
                min="0"
              />
            </div>
            <div className={s.inputWrap}>
              <label htmlFor="coef">Комиссия%</label>
              <input
                value={coef}
                onChange={(e) =>
                  onChange({
                    margin,
                    profit,
                    logistic,
                    ff,
                    tax,
                    coef: Number(e.target.value),
                    sale,
                    priemka,
                    ekv,
                    mila,
                  })
                }
                name="coef"
                type="number"
                min="0"
              />
            </div>
            <div className={s.inputWrap}>
              <label htmlFor="tax">Налог</label>
              <input
                value={tax}
                onChange={(e) =>
                  onChange({
                    margin,
                    profit,
                    logistic,
                    ff,
                    coef,
                    tax: Number(e.target.value),
                    sale,
                    priemka,
                    ekv,
                    mila,
                  })
                }
                name="tax"
                type="number"
                min="0"
              />
            </div>
            <div className={s.inputWrap}>
              <label htmlFor="sale">Скидка</label>
              <input
                value={sale}
                onChange={(e) =>
                  onChange({
                    margin,
                    profit,
                    logistic,
                    ff,
                    coef,
                    tax,
                    priemka,
                    ekv,
                    mila,
                    sale:
                      e.target.value === ""
                        ? undefined
                        : Math.min(Number(e.target.value), 0.99),
                  })
                }
                name="sale"
                type="number"
                min={0}
              />
            </div>
            {shop === "KATREN_OZON" ? (
              <>
                <div className={s.inputWrap}>
                  <label htmlFor="priemka">Приемка-обработка</label>
                  <input
                    value={priemka}
                    onChange={(e) =>
                      onChange({
                        margin,
                        profit,
                        logistic,
                        ff,
                        coef,
                        tax,
                        sale,
                        priemka: Number(e.target.value),
                        ekv,
                        mila,
                      })
                    }
                    name="priemka"
                    type="number"
                    min={0}
                  />
                </div>
                <div className={s.inputWrap}>
                  <label htmlFor="ekv">Эквайринг</label>
                  <input
                    value={ekv}
                    onChange={(e) =>
                      onChange({
                        margin,
                        profit,
                        logistic,
                        ff,
                        coef,
                        tax,
                        sale,
                        priemka,
                        ekv: Number(e.target.value),
                        mila,
                      })
                    }
                    name="ekv"
                    type="number"
                    min={0}
                  />
                </div>
                <div className={s.inputWrap}>
                  <label htmlFor="mila">Последняя миля</label>
                  <input
                    value={mila}
                    onChange={(e) =>
                      onChange({
                        margin,
                        profit,
                        logistic,
                        ff,
                        coef,
                        tax,
                        sale,
                        priemka,
                        ekv,
                        mila: Number(e.target.value),
                      })
                    }
                    name="mila"
                    type="number"
                    min={0}
                  />
                </div>
              </>
            ) : (
              ""
            )}
          </div>
          <div>
            <button
              onClick={() => {
                onSave({
                  margin: margin || 0,
                  profit: profit || 0,
                  logistic: logistic || 0,
                  ff: ff || 0,
                  coef: coef || 0,
                  tax: tax || 0,
                  sale: sale || 0,
                  priemka: priemka || 0,
                  ekv: ekv || 0,
                  mila: mila || 0,
                })
              }}
            >
              Сохранить
            </button>
          </div>
        </div>
        <div>
          <Calculator
            margin={margin}
            profit={profit}
            logistic={logistic}
            ff={ff}
            coef={coef}
            tax={tax}
            sale={sale}
            priemka={priemka}
            ekv={ekv}
            mila={mila}
            shop={shop}
          />
        </div>
      </div>
    </div>
  )
}
